@import './styles/typography';

body {
	margin: 0;
	font-family:
		Lato,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	width: 100vw;

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}

	#root {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	appearance: textfield;
}

.slick-list {
	overflow: clip;
	overflow-clip-margin: 3.875rem;
}
