.antd-custom-picker .ant-picker-input {
	padding: 10px 9px;
	border-radius: 4px;
	background-color: #f8f8fc;
	background-image: url('../../../assets/icons/calendar.svg');
	background-repeat: no-repeat;
	background-position: 95% 50%;
	height: 40px;
}
.antd-custom-picker-transparent .ant-picker-input {
	background-color: transparent;
}
.antd-custom-picker-transparent .ant-picker-input input::placeholder {
	color: #5e5e72 !important;
	opacity: 1 !important;
}
/* Borders of inputs */
.antd-custom-picker:not(.ant-picker-status-error) .ant-picker-input {
	border: 1.5px solid #e1e1ea !important;
}
.ant-picker-status-error .ant-picker-input {
	border: 1.5px solid #e61e50 !important;
}
/* Picker should extend */
.antd-custom-picker {
	width: 100%;
	margin: 0;
	padding: 0;
}
/* Disable active bar */
.antd-custom-picker .ant-picker-active-bar {
	display: none;
}
/* Spacing between rows */
.ant-picker-content {
	border-collapse: separate !important;
	border-spacing: 0 0.5em !important;
}
.ant-picker-content tr .ant-picker-cell-in-range:last-child::before {
	border-top-right-radius: 40% !important;
	border-bottom-right-radius: 40% !important;
}

.ant-picker-content tr .ant-picker-cell-in-range:first-child::before {
	border-top-left-radius: 40% !important;
	border-bottom-left-radius: 40% !important;
}

/* Cell Rendering */
.ant-picker-cell-inner {
	width: 30px !important;
	height: 30px !important;
}
.ant-picker-cell-range-start .ant-picker-inner-cell-selected-typography {
	color: white !important;
}
.ant-picker-cell-range-end .ant-picker-inner-cell-selected-typography {
	color: white !important;
}
.ant-picker-cell:not(.ant-picker-cell-in-range) .ant-picker-cell-inner {
	border-radius: 50% !important;
}

.ant-picker-cell {
	padding: 0 !important;
	margin-top: 5px !important;
}
.ant-picker-cell-in-range::before {
	background: #01884c1a !important;
	height: 100% !important;
}
.ant-picker-cell-range-start::before {
	background: #01884c1a !important;
	height: 100% !important;
}
.ant-picker-cell-selected:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end)::before {
	background: transparent !important;
	height: 100% !important;
}

.ant-picker-cell-range-end::before {
	inset-inline: 0 30% !important;
	background: #01884c1a !important;
	height: 100% !important;
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
	height: 100% !important;
	border-radius: 50% !important;
	background: rgb(1 136 76 / 80%) !important;
}
.ant-picker-cell-selected:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end) .ant-picker-cell-inner {
	height: 100% !important;
	border-radius: 50% !important;
	background: transparent !important;
}
.ant-picker-cell-selected:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end) .ant-picker-cell-inner p {
	color: '#0F1A2E' !important;
}

.ant-picker-cell-range-end .ant-picker-cell-inner {
	height: 100% !important;
	border-radius: 50% !important;
	background: rgb(1 136 76 / 80%) !important;
}
.ant-picker-content tbody tr {
	margin-top: 5px !important;
}
.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-color: transparent !important;
}

.ant-picker-cell-range-hover-end .ant-picker-cell-inner::before {
	background: transparent !important;
}

.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range, .ant-picker-cell-range-start)::before {
	background: transparent !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner::after {
	background: transparent !important;
}

.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range) .ant-picker-cell-inner::after {
	background: transparent !important;
}

.ant-picker-cell-range-hover::after {
	border-color: #01884c !important;
	border-width: 1px !important;
	height: 100% !important;
}
.ant-picker-cell-range-hover-start::after {
	border-color: #01884c !important;
	border-top-left-radius: 40% !important;
	border-bottom-left-radius: 40% !important;
	height: 101% !important;
}
.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range)::after {
	border-color: #01884c !important;
	border-top-right-radius: 40% !important;
	border-bottom-right-radius: 40% !important;
	height: 101% !important;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) {
	visibility: hidden !important;
	height: 0px !important;
}
.ant-picker-cell:not(.ant-picker-cell-in-view)::before {
	visibility: hidden !important;
	height: 0px !important;
}

/* Periods hover has background */
.ant-picker-panel-container .ant-picker-presets ul li:hover {
	background: transparent !important;
}

/* Makes title of presets not clickable */
.ant-picker-panel-container .ant-picker-presets ul li:first-child {
	pointer-events: none !important;
}

.PrivatePickersYear-yearButton {
	font-size: 12px !important;
	line-height: 21.6px !important;
}

/* Year panel extensions */
.ant-picker-year-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-inner {
	height: 50px !important;
	width: 50px !important;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	margin-left: auto;
	margin-right: auto;
}

.ant-picker-year-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-range-end::before {
	height: 50px !important;
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
}

.ant-picker-year-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-range-start::before {
	height: 50px !important;
}

.ant-picker-year-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-in-range::before {
	height: 50px !important;
}
.ant-picker-decade-btn {
	pointer-events: none !important;
}

/* Month panel extensions */
.ant-picker-month-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-inner {
	height: 50px !important;
	width: 50px !important;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	margin-left: auto;
	margin-right: auto;
}

.ant-picker-month-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-range-end::before {
	height: 50px !important;
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
}

.ant-picker-month-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-range-start::before {
	height: 50px !important;
}

.ant-picker-month-panel .ant-picker-body .ant-picker-content tbody tr .ant-picker-cell-in-range::before {
	height: 50px !important;
}
